import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardWithAnimatedIcon from '../CardWithAnimatedIcon';
import Hero from '../Hero';
import styles from './HeroWithButtons.module.scss';

const HeroWithButtons = ({
    title = '',
    text = '',
    subTitle = '',
    image = {},
    globalButtons = [],
    ctaButtons = [],
}) => {
    const hasSubTitle = !!subTitle;
    const buttons = [...globalButtons, ...ctaButtons];
    const hasButtons = buttons.length > 0;

    const classes = classNames({
        [styles['Hero__OffsetContent']]: hasSubTitle || hasButtons,
        [styles['Hero__OffsetContent--Subtitle']]: hasSubTitle && hasButtons,
    });

    return (
        <>
            <Hero
                {...{ image, text, title }}
                modifiers={hasSubTitle ? ['subtitle'] : ['withOffsetContent']}
            />
            <div className={classes}>
                {subTitle && (
                    <h2
                        className={styles['Hero__Title']}
                        dangerouslySetInnerHTML={{ __html: subTitle }}
                    />
                )}
                {hasButtons && (
                    <div className={styles['Hero__ButtonContainer']}>
                        {buttons.map((button, index) => (
                            <CardWithAnimatedIcon
                                key={index}
                                index={index}
                                link={button.link}
                                align={'center'}
                                {...button}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

HeroWithButtons.propTypes = {
    title: PropTypes.string,
    image: PropTypes.shape({
        renditions: PropTypes.shape({
            mobile: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            mobile2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
    }),
    text: PropTypes.string,
    subTitle: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.shape({
                href: PropTypes.string,
                target: PropTypes.string,
            }),
            icon: PropTypes.string,
            text: PropTypes.string,
        })
    ),
};

export default HeroWithButtons;
